import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import { getUserAndTeam } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  dataLoaded: false
};

class SprintLeaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {

    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({ 
            dataLoaded: true, 
            user: user, 
            team : team, 
         });
      },
      () => {
        this.setState({ isOffline: true })
      })
    }
  }
  
  render = () => {
    var history = this.props.history;

    return (
      <AppChrome title="Habits" history={history} requireLogin={true} user={this.state.user} team={this.state.team} firebase={this.props.firebase} tab="Habits">
        <p>Leaderboard: Under construction</p>
      </AppChrome>
    )
  }
}

export default SprintLeaderboard;