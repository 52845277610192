import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';

import { getUserAndTeam } from '../util/DatabaseUtils';

import { setExperience } from '../util/ExperienceUtils';

const INITIAL_STATE = {
  dataLoaded: false
};

class SprintHome extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {

    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({ 
            dataLoaded: true, 
            user: user, 
            team : team, 
         });
      },
      () => {
        this.setState({ isOffline: true })
      })
    }
  }

  switchUX = () => {
    setExperience('habits')

    this.props.history.push('/')
  }
  
  render = () => {
    var history = this.props.history;

    return (
      <AppChrome title="Habits" history={history} requireLogin={true} user={this.state.user} team={this.state.team} firebase={this.props.firebase} tab="Habits">
        <p>Home: Under construction</p>
        <div className='homePageSwitchButton'>
            <Button onClick={this.switchUX} action='Switch UX'/>
          </div>
      </AppChrome>
    )
  }
}

export default SprintHome;