import React, { Component } from 'react';

import AppHeader from '../layout/AppHeader';
import AppFooter from '../layout/AppFooter';
import AppSidebar from '../layout/AppSidebar';
import AppWelcomeForm from '../layout/AppWelcomeForm';

import toast, { Toaster } from 'react-hot-toast';

/**
 * Props
 *   back - back url (or "")
 *   firebase - firebase object
 *   history - history object
 *   requireLogin - require user to be logged in
 *   tab - name of tab ("Home", "Activity", "Habits", "Discover")
 *   title - Page title * 
 */
class AppChrome extends Component {

    constructor(props) {
       super(props);
    }

    render = () => {
      var user = this.props.firebase.user
      var userLoaded = this.props.firebase.initialised;

      return (
        <div className='App-pageframeOuter'>
            <div className='App-pageframeInner'>
                <Toaster reverseOrder={true} position="top-center"/>

                <AppHeader title={this.props.title} team={this.props.team} history={this.props.history} back={(this.props.back) ? true : false} firebase={this.props.firebase} />
                <AppSidebar tab={this.props.tab} history={this.props.history} firebase={this.props.firebase}/>
              
                <div className='App-content'>
                    <div className='App-content-column'>
                    {
                      (this.props.requireLogin && !userLoaded) ?
                        <div/>
                      :
                      (!this.props.requireLogin || user) ?
                        this.props.children
                      :
                      <AppWelcomeForm history={this.props.history}/>
                    }
                    </div>
                </div>
                <AppFooter firebase={this.props.firebase} tab={this.props.tab} history={this.props.history}/>
          </div>
        </div>
      )
    }
}

export default AppChrome;