
var experience = 'habits';

export function getExperience() {
    return experience
}

export function setExperience(e) {
    experience = e 
}


  